import React from 'react';
import './App.css';
import Resume from './resume.pdf';

let Row = (props) => {
	return <div className="row">{props.children}</div>
}
let Col = (props) => {
	return <div className="col">{props.children}</div>
}
function App() {
	return (
		<div className="page">
			<Row center="xs">
				<Col>
					<h1>HELLO! I'M <span class="name">ERIC HURST</span></h1>
				</Col>
			</Row>
			<Row center="xs">
				<Col>
					<img src="https://avatars.githubusercontent.com/u/2236038?v=4" alt="RegionSyx" />
				</Col>
			</Row>
			<Row start="xs">
				<p>
					I am a Software Developer currently working for <a href="https://we.co">WeWork</a> in Salt
					Lake City. I graduated from the Univeristy of Utah in 2014. Since then, I have honed my
					skills as a developer, learning a wide variety of technologies, from javascript to
					databases.
				</p>
				<p>
					When not coding, I enjoy spending time with my son or playing video games
					like Factorio or League of Legends.
				</p>

			</Row>
			<Row between="xs" center="md">
				<Col xs={6} md={2}>
					<a class="resume" href={Resume}>RESUME</a>
				</Col>
				<Col xs={6} md={2}>
					<a class="github" href="mailto:eric.hurst97@gmail.com">EMAIL</a>
				</Col>
				<Col xs={6} md={2}>
					<a class="github" href="https://github.com/RegionSyx">GITHUB</a>
				</Col>
				<Col xs={6} md={2}>
					<a class="linkedin" href="https://www.linkedin.com/in/regionsyx/">LINKEDIN</a>
				</Col>
				<Col xs={6} md={2}>
					<a class="twitter" href="https://twitter.com/rgsyx">TWITTER</a>
				</Col>
				<Col xs={6} md={2}>
					<a class="twitch" href="https://twitch.tv/RegionSyx">TWITCH</a>
				</Col>
			</Row>

		</div>
	);
}

export default App;
